import { storeToRefs } from 'pinia'
import { useGameStore } from '~~/store/game'
import { IProvider } from '~/types/lobby.type'
import {
  IMenuUserDesktop,
  IMenuWithDraw,
  IMenuDepositBank,
  IMenuDepositDesktop,
  IMenuDeposit,
  IMenuMobile,
  IMenuBottom
} from '~/types/menu.type'
import { ACCOUNT_URLS, LOBBY_CASINO_URLS, LOBBY_GAME_URLS, PAGE_URLS } from '~/config/page-url'
import { LABEL } from '~/constants/account/history/transactions'

export const PATH_ICON_FOOTER = '/assets/images/components/mobile/footer/'
// export const PATH_ICON = 'images/icon/'
export const PATH_ICON = '/assets/images/components/desktop/menu/'
export const PATH_ICON_ACCOUNT = '/assets/images/components/desktop/menu/account/'

export const LOGO_SITE_IMAGE = '/assets/images/logo.svg'

export const IMAGE_PATH_ACCOUNT = '/assets/images/components/desktop/pages/account/layout/'
export const IMAGE_PATH_DEPOSIT = '/assets/images/components/desktop/pages/account/deposit/'
export const TRANSACTION_P2P_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}
export const getMenuHeader = () => {
  const { $config, $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  return {
    MENUS: [
      {
        type: 'sports',
        name: 'sports',
        tab: 'tab-sport',
        title: 'Thể thao',
        subTitle: 'Thể loại',
        titleRecommend: 'Nhà cung cấp No.1',
        itemRecommend: {
          img: '/assets/images/components/desktop/pages/home/header-recomment-sport.webp',
          linkRecommend: '/the-thao/ksports'
        },
        activeUrl: '/the-thao',
        subMenu: []
      },
      {
        type: 'livecasino-type',
        name: 'casino',
        title: 'Live Casino',
        subTitle: 'Thể loại',
        tab: 'tab-casino',
        activeUrl:
          PAGE_URLS.CASINO +
          '/' +
          (providerCasino?.value && providerCasino.value.length > 0
            ? (providerCasino?.value[0] as IProvider)?.alias
            : 'all'),
        rootActiveUrl: PAGE_URLS.CASINO,
        titleRecommend: 'Game đề xuất',
        itemRecommend: {
          img: '/assets/images/components/desktop/pages/home/header-recomment-casino.webp',
          name: 'B52 Xóc dĩa',
          partner_game_id: 'vgmn_109',
          partner_provider: 'b52',
          report_game_id: 'vgmn_109',
          maintain: false,
          deny_info: 'WELCOME',
          is_favorite: false,
          type: 'casino'
        },
        subMenu: []
      },
      {
        name: 'quay-so',
        title: 'Quay Số',
        tab: 'tab-games',
        activeUrl: LOBBY_GAME_URLS.QUAY_SO,
        subMenu: []
      },
      {
        type: 'games',
        name: 'game-type',
        title: 'Cổng Games',
        subTitle: 'Thể loại',
        tab: 'tab-games',
        activeUrl:
          PAGE_URLS.GAME +
          '/' +
          (providerGames?.value && providerGames.value.length > 0
            ? (providerGames?.value[0] as IProvider)?.alias
            : 'game-bai'),
        rootActiveUrl: PAGE_URLS.GAME,
        titleRecommend: 'Game đề xuất',
        itemRecommend: {
          img: '/assets/images/components/desktop/pages/home/header-recomment-game.webp',
          name: 'Go88 Tài Xỉu',
          partner_game_id: 'qs_xocdia-102',
          partner_provider: 'go',
          report_game_id: 'qs_xocdia-102',
          maintain: false,
          deny_info: 'WELCOME',
          is_favorite: false,
          type: 'game'
        },
        subMenu: []
      },
      {
        name: 'khuyen-mai',
        title: 'Khuyến mãi',
        tab: 'tab-promotion',
        activeUrl: '/khuyen-mai',
        subMenu: []
      }
    ]
  }
}

export const USER_MENU_HEADER: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: PATH_ICON_ACCOUNT + 'help-register.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-register'
  },
  {
    id: 2,
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: PATH_ICON_ACCOUNT + 'p2p.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'p2p'
  },
  {
    id: 3,
    name: 'Lịch sử giao dịch',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: PATH_ICON_ACCOUNT + 'icon-history.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.TRANSACTION_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history'
  },
  {
    id: 3,
    name: 'Lịch sử cá cược',
    url: ACCOUNT_URLS.BET_HISTORY,
    icon: PATH_ICON_ACCOUNT + 'icon-history-bet.svg',
    activeUrl: ACCOUNT_URLS.BET_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history-bet'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: PATH_ICON_ACCOUNT + 'icon-logout.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]
export const USER_MENU: IMenuUserDesktop[] = [
  {
    id: 1,
    name: 'Tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: PATH_ICON_ACCOUNT + 'help-register.svg',
    activeUrl: ACCOUNT_URLS.PROFILE,
    activeUrlRelative: ACCOUNT_URLS.PROFILE,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-profile'
  },
  {
    id: 2,
    name: 'Nạp tiền',
    url: ACCOUNT_URLS.DEPOSIT_DA,
    icon: PATH_ICON_ACCOUNT + 'icon-deposit.svg',
    activeUrl: ACCOUNT_URLS.DEPOSIT_DA,
    activeUrlRelative: ACCOUNT_URLS.DEPOSIT,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-deposit'
  },
  {
    id: 3,
    name: 'Rút tiền',
    url: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-withdraw.svg',
    activeUrl: ACCOUNT_URLS.WITHDRAW_BANK,
    activeUrlRelative: ACCOUNT_URLS.WITHDRAW,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-withdraw'
  },
  {
    id: 4,
    name: 'Giao dịch P2P',
    url: ACCOUNT_URLS.P2P,
    icon: PATH_ICON_ACCOUNT + 'icon-p2pc.svg',
    activeUrl: ACCOUNT_URLS.P2P,
    activeUrlRelative: ACCOUNT_URLS.P2P,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-p2pc'
  },
  {
    id: 5,
    name: 'Lịch sử',
    url: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: PATH_ICON_ACCOUNT + 'icon-history.svg',
    activeUrl: ACCOUNT_URLS.TRANSACTION_HISTORY,
    activeUrlRelative: ACCOUNT_URLS.BET_HISTORY,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-history'
  },
  // {
  //   id: 6,
  //   name: 'Tiền thưởng',
  //   url: ACCOUNT_URLS.PROFIT_TODAY,
  //   icon: PATH_ICON_ACCOUNT + 'icon-bonus.svg',
  //   activeUrl: ACCOUNT_URLS.PROFIT_TODAY,
  //   activeUrlRelative: ACCOUNT_URLS.PROFIT_TODAY,
  //   hiddenInTab: false,
  //   isLogout: false,
  //   img: 'icon-bonus'
  // },
  {
    id: 7,
    name: 'Ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: PATH_ICON_ACCOUNT + 'icon-bank.svg',
    activeUrl: ACCOUNT_URLS.BANK,
    activeUrlRelative: ACCOUNT_URLS.BANK,
    hiddenInTab: false,
    isLogout: false,
    img: 'icon-bank'
  },
  {
    id: 8,
    name: 'Đăng xuất',
    url: '',
    icon: PATH_ICON_ACCOUNT + 'icon-logout.svg',
    activeUrl: '',
    activeUrlRelative: '',
    hiddenInTab: false,
    isLogout: true
  }
]

export const MENU_WITHDRAW: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  },
  {
    name: 'Giao dịch P2P',
    desc: 'An toàn, uy tín',
    queryValue: 'sell',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`,
    icon: 'icon-p2pc',
    iconMB: 'icon-p2pc',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.SELL}`
  }
]

export const MENU_WITHDRAW_DESKTOP: IMenuWithDraw[] = [
  {
    name: 'Ngân hàng',
    desc: 'Duyệt rút nhanh chóng',
    queryValue: 'bank',
    fullPath: ACCOUNT_URLS.WITHDRAW_BANK,
    icon: 'icon-bank',
    iconMB: 'icon-bank',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/bank/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_BANK
  },
  {
    name: 'Tiền ảo',
    desc: 'Duyệt tự động',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.WITHDRAW_CRYPTO,
    icon: 'icon-crypto',
    iconMB: 'icon-crypto',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/crypto/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CRYPTO
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ 5 thẻ / lần',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.WITHDRAW_CARD,
    icon: 'icon-card',
    iconMB: 'icon-card',
    isHot: false,
    isNew: false,
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/withdraw/card/index.vue')),
    link: ACCOUNT_URLS.WITHDRAW_CARD
  }
]

export const MENU_DEPOSIT: IMenuDeposit[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    key: 'nicepayBanks',
    desc: 'Khuyến mãi hấp dẫn',
    icon: 'icon-codepay',
    isHot: true,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-codepay2',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/deposit/bank/index.vue')),
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: true,
    isMaintain: false
  },
  {
    name: 'Ví điện tử',
    queryValue: 'momo',
    fullPath: ACCOUNT_URLS.DEPOSIT_MOMO,
    key: 'momos',
    desc: 'Momo, Viettel Money',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ cào',
    desc: 'Hỗ trợ nhiều nhà mạng',
    queryValue: 'card',
    fullPath: ACCOUNT_URLS.DEPOSIT_CARD,
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Giao dịch P2P',
    desc: 'An toàn, uy tín',
    queryValue: 'buy',
    fullPath: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    key: 'buy',
    icon: 'icon-p2pc',
    isHot: false,
    isNew: false,
    component: null,
    componentMobile: null,
    link: `${ACCOUNT_URLS.TRANSACTION_P2P}?type=${TRANSACTION_P2P_TYPE.BUY}`,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_DESKTOP: IMenuDepositDesktop[] = [
  {
    name: 'Ngân Hàng',
    queryValue: 'codepay',
    fullPath: [ACCOUNT_URLS.DEPOSIT_BANK, ACCOUNT_URLS.DEPOSIT_DA, ACCOUNT_URLS.DEPOSIT_CODEPAY2],
    key: 'nicepayBanks',
    desc: 'Quét mã thanh toán tự động',
    icon: 'icon-bank',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    component2: null,
    componentMobile: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Tiền Ảo',
    desc: 'Tặng 0.8%',
    key: 'crypto_v2',
    queryValue: 'crypto',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CRYPTO],
    icon: 'icon-crypto',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/crypto/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CRYPTO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Ví Điện Tử',
    queryValue: 'e-wallet',
    fullPath: [ACCOUNT_URLS.DEPOSIT_MOMO, ACCOUNT_URLS.DEPOSIT_VIETTEL_PAY, ACCOUNT_URLS.DEPOSIT_ZALO_PAY],
    key: 'e-wallet',
    desc: 'Siêu tiện lợi, nhanh chóng',
    icon: 'icon-wallets',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/e-wallet/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_MOMO,
    isPromotion08: false,
    isMaintain: false
  },
  {
    name: 'Thẻ Cào',
    desc: 'Mệnh giá đa dạng',
    queryValue: 'card',
    fullPath: [ACCOUNT_URLS.DEPOSIT_CARD],
    key: 'cardList',
    icon: 'icon-card',
    isHot: false,
    isNew: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/card/index.vue')),
    componentMobile: null,
    component2: null,
    componentMobile2: null,
    link: ACCOUNT_URLS.DEPOSIT_CARD,
    isPromotion08: false,
    isMaintain: false
  }
]

export const MENU_DEPOSIT_BANK: IMenuDepositBank[] = [
  {
    name: 'CodePay',
    queryValue: 'codepay',
    fullPath: ACCOUNT_URLS.DEPOSIT_DA,
    pathActive: ACCOUNT_URLS.DEPOSIT_BANK,
    key: 'nicepayBanks',
    icon: 'icon-codepay',
    iconActive: 'icon-codepay',
    isHot: true,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_DA,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  },
  {
    name: 'CodePay2',
    queryValue: 'codepay2',
    fullPath: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    key: 'providerGoPay',
    icon: 'icon-codepay2',
    iconActive: 'icon-codepay2',
    isHot: false,
    isNew: false,
    link: ACCOUNT_URLS.DEPOSIT_CODEPAY2,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/deposit/bank/index.vue')),
    componentMobile: null,
    isMaintain: false
  }
]

export const MENU_HISTORY = [
  {
    name: LABEL.HISTORY_TRANSACTIONS,
    link: ACCOUNT_URLS.TRANSACTION_HISTORY,
    icon: 'icon-history-transaction',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/transactions/index.vue')),
    componentMobile: defineAsyncComponent(
      () => import('~/components/mobile/pages/account/history/transactions/index.vue')
    )
  },
  {
    name: LABEL.HISTORY_BETS,
    link: ACCOUNT_URLS.BET_HISTORY,
    icon: 'icon-history-bets',
    isMaintain: false,
    component: defineAsyncComponent(() => import('~/components/desktop/pages/account/history/bets/index.vue')),
    componentMobile: defineAsyncComponent(() => import('~/components/mobile/pages/account/history/bet/index.vue'))
  }
]

export const NOTE = [
  'Nạp / Rút bằng tài khoản chính chủ.',
  'Hỗ trợ chuyển tiền liên ngân hàng.',
  'Lưu lại biên lai để đối chiếu khi cần.'
]

export const USER_MENU_MOBILE: IMenuMobile[] = [
  {
    name: 'Quản lý tài khoản',
    url: ACCOUNT_URLS.PROFILE,
    icon: 'icon-profile',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Quản lý ngân hàng',
    url: ACCOUNT_URLS.BANK,
    icon: 'icon-bank',
    activeUrl: [ACCOUNT_URLS.PROFILE],
    isLiveChat: false
  },
  {
    name: 'Giao dịch P2P',
    url: `${ACCOUNT_URLS.TRANSACTION_P2P}`,
    icon: 'icon-p2pc',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_P2P],
    isLiveChat: false
  },
  {
    name: 'Lịch sử giao dịch',
    url: `${ACCOUNT_URLS.TRANSACTION_HISTORY}`,
    icon: 'icon-history-transaction',
    activeUrl: [ACCOUNT_URLS.TRANSACTION_HISTORY],
    isLiveChat: false
  },
  {
    name: 'Lịch sử cá cược',
    url: `${ACCOUNT_URLS.BET_HISTORY}`,
    icon: 'icon-history-bets',
    activeUrl: [ACCOUNT_URLS.BET_HISTORY],
    isLiveChat: false
  },
  // {
  //   name: 'Tiền thưởng',
  //   url: `${PAGE_URLS.BONUS}`,
  //   icon: 'icon-bonus',
  //   activeUrl: [PAGE_URLS.BONUS],
  //   isLiveChat: false,
  //   iconUrl: '/assets/images/common/icon-promotion2.svg'
  // },
  {
    name: 'Live chat 24/7',
    url: '',
    icon: 'icon-livechat',
    activeUrl: [],
    isLiveChat: true
  }
]

export const BOTTOM_MENU: IMenuBottom[] = [
  {
    name: 'Khuyến mãi',
    url: PAGE_URLS.PROMOTION,
    activeUrl: PAGE_URLS.PROMOTION,
    icon: PATH_ICON_FOOTER + 'promotion.svg'
  },
  {
    name: 'Trang chủ',
    url: PAGE_URLS.HOME,
    activeUrl: PAGE_URLS.HOME,
    icon: PATH_ICON_FOOTER + 'home.svg'
  },
  {
    name: 'Tìm kiếm',
    url: PAGE_URLS.SEARCH,
    activeUrl: PAGE_URLS.SEARCH,
    icon: PATH_ICON_FOOTER + 'search.svg'
  },
  {
    name: 'Thể thao',
    url: PAGE_URLS.SPORT,
    activeUrl: PAGE_URLS.SPORT,
    icon: PATH_ICON_FOOTER + 'sport.svg'
  },
  {
    name: 'Cổng games',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: PAGE_URLS.GAME,
    icon: PATH_ICON_FOOTER + 'table-games.svg'
  },
  {
    name: 'Live casino',
    url: LOBBY_CASINO_URLS.ALL,
    activeUrl: PAGE_URLS.CASINO,
    icon: PATH_ICON_FOOTER + 'live-casino.svg'
  }
]
